<template>
  <div>
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="$router.back()" alt="" />
        <span>用户详情</span>
      </div>
    </div>
    <div class="page">
      <div class="box-top">
        <div class="box-top-left">
          {{ infor.name }}
          <span :class="infor.status == 1 ? 'green' : 'red'"></span>
          <el-switch class="switchRem" v-model="infor.status" @change="updateUserStatus($event,infor.name)" :active-value="1" :inactive-value="0"
                     active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
          <span>{{infor.status == 1?'已启用':'已停用'}}</span>
        </div>
        <!-- <el-button @click="dialogVisible = true" size='small' type="info">返回</el-button> -->
        <!-- <div class="blueBtn1" @click="$router.back()">返 回</div> -->
      </div>
      <div class="box-title">
        <ul class="box-title-left">
          <li @click="jump(item)" v-for="(item, inx) in navList" :key="inx" :class="navItem == item ? 'itemLi' : ''">
            {{ item }}
          </li>
        </ul>
        <div>
          <el-button icon="el-icon-edit-outline" type="primary" @click="editUserInfo" plain>修改
          </el-button>
          <el-button type="danger" @click="deleteOrgUser">删除用户</el-button>
        </div>
      </div>
      <div class="box-content">
        <div class="box-content-title">
          <span>基本信息</span>
          <!-- <el-button icon="el-icon-edit-outline" type="primary" plain>编辑</el-button> -->
        </div>
        <ul class="box-content-content">
          <li>
            <div class="box-content-content-left">姓名</div>
            <div class="box-content-content-right">
              {{ infor.name }}
            </div>
          </li>
          <li>
            <div class="box-content-content-left">账号</div>
            <div class="box-content-content-right">
              {{ infor.phoneNum }}
            </div>
          </li>
          <li>
            <div class="box-content-content-left">密码</div>
            <div class="box-content-content-right">
              **********
            </div>
          </li>
          <li>
            <div class="box-content-content-left">所属用户组</div>
            <div class="box-content-content-right">
              {{ infor.orgName }}
            </div>
          </li>
          <li>
            <div class="box-content-content-left">创建时间</div>
            <div class="box-content-content-right">
              {{ infor.bindingTime }}
            </div>
          </li>
          <li>
            <div class="box-content-content-left">最后登录时间</div>
            <div class="box-content-content-right">
              {{ infor.lastLoginTime }}
            </div>
          </li>
        </ul>
      </div>
      <el-dialog title="修改信息" :visible.sync="editInforVisible" width="30%">
        <div class="addpersonnel">
          <ul>
            <li>
              <p class="p-title"><span>*</span> 姓名</p>
              <el-input style="width: 100%" v-model="editInfor.name" placeholder="请输入姓名"></el-input>
            </li>
            <li>
              <p class="p-title"><span>*</span> 手机号(账号)</p>
              <el-input style="width: 100%" v-model="editInfor.phoneNum" placeholder="请输入电话"></el-input>
            </li>
            <li>
              <p class="p-title"><span>*</span> 密码</p>
              <el-input style="width: 100%" v-model="editInfor.passWord" placeholder="请输密码" show-password></el-input>
            </li>
          </ul>
        </div>
        <span slot="footer">
          <el-button @click="editInforVisible = false">取 消</el-button>
          <el-button type="primary" @click="editUserInfoSubmit(setWeChar)">确 定</el-button>
        </span>
        <!-- <div class="dialog-footer">
          <div class="dialogCancel" @click="editInforVisible = false">取 消</div>
          <div class="dialogDetermine" @click="editUserInfoSubmit(setWeChar)">确 定</div>
        </div> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getUserInfo, //获取用户详情
  // getUserProdList,//获取用户管理账号配置列表
  disableStatus, //停用用户
  enableStatus, //启用用户
  deleteOrgUser, //删除当前用户
  editUserInfo, //修改当前用户信息
  // orgRoleList, //下拉角色列表
  // queryAllName, //获取微信用户
} from "../../api/account/index";
export default {
  data() {
    return {
      isAdmin: 0,//是否是产品管理员
      setWeChar: false,//判断微信是否为空
      roleValue: "", //选择角色
      options: [], //存储选角色
      editInforVisible: false, //修改用户信息的显隐
      dialogVisible: false,
      value: true,
      navItem: "用户详情",
      navList: ["用户详情"],
      tableData: [],
      infor: {},
      editInfor: {
        wechatNameId: "", //微信关联id
        name: "", //姓名
        phoneNum: "", //电话
        passWord:''
      },
      wechatAllUserList: [], //存储微信用户
      multipleSelection: [],
      page: {
        currentPage: 1,
        pageSize: 15,
        total: 0,
      },
    };
  },
  mounted() {
    this.getUserInfo();
    //   this.getUserProdList()
    // this.getOrgRoleList();
  },
  methods: {
    // 获取下拉角色列表
    async getOrgRoleList() {
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        orgId: this.infor.orgId,
      };
      let res = await orgRoleList(data);
      if (res.data.code == 200) {
        this.options = res.data.data.list.map((item) => {
          return {
            label: item.roleName,
            value: item.id,
          };
        });
      }
    },
    // 修改当前用户基本信息按钮
    editUserInfo() {
      this.editInforVisible = true;
      this.editUserLoading = false
      this.editInfor = {
        // wechatNameId: this.infor.wechatNameId, //微信关联id
        name: this.infor.name, //姓名
        phoneNum: this.infor.phoneNum, //电话
        passWord:this.infor.passWord
      };
      this.roleValue = 0;
    },
    // 修改当前用户基本信息提交按钮
    async editUserInfoSubmit(setWeCharName) {
      let data = {
        orgUserId: this.$route.query.id * 1,
        // wechatNameId: setWeCharName ? 0 : this.editInfor.wechatNameId, //微信关联id
        name: this.editInfor.name, //姓名
        phoneNum: this.editInfor.phoneNum, //电话
        orgId: this.infor.orgId, //机构ID
        passWord:this.editInfor.passWord,
        role: 0,
      };
      this.editUserLoading = true
      let res = await editUserInfo(data);
      if (res.data.code == 200) {
        this.$message.success("修改成功");
        this.getUserInfo();
        this.editUserLoading = false
        this.editInforVisible = false;
        this.setWeChar = false
      }
    },
    // 删除当前用户
    deleteOrgUser() {
      this.$confirm("是否确定删除产品账号？", "操作提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await deleteOrgUser([this.$route.query.id * 1]);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.$router.push("/account");
        }
      });
    },
    // 更新用户状态
    async updateUserStatus(val, name) {
      let data = {
        orgUserId: this.$route.query.id,
        status: val,
        operationDetails: val == 1 ? `启用了${name}` : `停用了${name}`
      };
      if (val == 1) {
        //启用
        let res = await enableStatus(data);
        if (res.data.code == 200) {
          this.$message.success("启用成功");
          this.getUserInfo();
        }
      } else if (val == 0) {
        //停用
        let res = await disableStatus(data);
        if (res.data.code == 200) {
          this.$message.success("停用成功");
          this.getUserInfo();
        }
      }
    },
    // 根据id获取用户详情
    async getUserInfo() {
      let res = await getUserInfo(this.$route.query.id);
      if (res.data.code == 200) {
        this.infor = res.data.data;
      }
    },
    // 获取用户产品账号配置列表
    // async getUserProdList(){
    //     let data = {
    //         page:this.page.currentPage,
    //         limit:this.page.pageSize
    //     }
    //     let res = await getUserProdList(data)
    //     if(res.data.code==200){
    //         this.tableData = res.data.data.list
    //         this.page.total = res.data.data.totalCount
    //     }
    // },
    // 分页列表函数
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.currentPage = 1;
    },
    // 分页页码函数
    handleCurrentChange(val) {
      this.page.currentPage = val;
    },
    editUserProd() {
      this.dialogVisible = true;
    },
    submitForm() { },
    jump(item) {
      this.navItem = item;
    },
    // 模糊查询微信昵称  有参数时
    // 获取微信列表 无参数时
    async getWeChatAllName(nickName) {
      let res = await queryAllName(nickName);
      if (res.data.code === 200) {
        this.wechatAllUserList = res.data.data.list.map((x) => {
          return {
            label: x.nickname,
            value: x.wxUserId,
            headImg: x.headimgurl,
            time: x.subscribeTime,
            focus: x.publicAccount,
          };
        });
        this.wechatAllUserList.push({
          label: this.infor.wechatName,
          value: this.infor.wechatNameId,
        })
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.head {
  display: flex;
  width: 100%;
  height: 56px;
  background: #ffffff;
  position: fixed;
  margin-top: 0;
  // z-index: 100;
  position: relative;
  align-items: center;
  .head-left {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: center;
    img {
      height: 38px;
      width: 38px;
      margin-left: 23px;
      margin-right: 15px;
      cursor: pointer;
    }
    span {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  .img {
    position: absolute;
    right: 25px;
    height: 38px;
    // width: 38px;
    margin-left: 23px;
    margin-right: 15px;
    cursor: pointer;
  }
}
.page {
  .box-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .box-top-left {
      display: flex;
      font-size: 20px;

      font-weight: bold;
      color: #0c2b4a;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }

  .box-title {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    .box-title-left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0c2b4a;

      li {
        margin-right: 72px;
        padding: 20px 0;
        cursor: pointer;
      }

      .itemLi {
        font-size: 16px;
        font-weight: 500;
        color: #f58030;
        border-bottom: 4px solid #f58030;
      }
    }
  }

  .box-content {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.12);
    padding: 20px 30px;
    margin-top: 10px;

    .box-content-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #0c2b4a;
        padding-left: 10px;
        border-left: 4px solid #f58030;
      }
    }
    .box-content-content {
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        width: 30%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0c2b4a;
        margin-right: 50px;
        margin-top: 20px;

        .box-content-content-left {
          min-width: 120px;
          height: 40px;
          line-height: 40px;
          background: #f6f7fb;
          border-radius: 10px 0px 0px 10px;
          margin-right: 2px;
          padding-left: 13px;
        }

        .box-content-content-right {
          width: 350px;
          height: 40px;
          line-height: 40px;
          background: #f6f7fb;
          border-radius: 0px 10px 10px 0px;
          padding-left: 20px;
        }
      }
    }

    .box-content-bottom {
      display: flex;
      flex-wrap: wrap;

      .box-content-bottom-div {
        width: 348px;
        background: #ffffff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        margin-top: 27px;
        margin-right: 54px;

        .box-content-bottom-div-title {
          display: flex;
          justify-content: space-between;

          img {
            width: 230px;
            height: 142px;
          }

          .box-content-bottom-div-title-right {
            text-align: right;
            padding: 20px;
          }
        }

        .box-content-bottom-div-center {
          padding: 20px;

          h1 {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #0c2b4a;
          }

          .box-content-bottom-div-center-div {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #6f798f;
            margin-top: 10px;
          }

          .box-content-bottom-div-center-btn {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: rgba(63, 71, 255, 0.16);
            border-radius: 16px;
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #3f47ff;
            margin-top: 20px;
          }
        }

        .box-content-bottom-div-bottom {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          align-items: center;
          height: 60px;
          border-top: 1px solid #e7ecf0;
        }
      }

      .add-box-content-bottom-div {
        width: 348px;
        height: 380px;
        background: #f5f8fe;
        border-radius: 10px;
        margin-top: 27px;
        text-align: center;
        padding-top: 166px;
        cursor: pointer;

        .icon-jiahao {
          color: #277dff;
        }

        p {
          margin-top: 40px;
        }
      }
    }
  }

  .adddialog {
    ul {
      padding: 0 91px;

      li {
        margin-top: 20px;

        .p-title {
          font-size: 14px;
          font-weight: 500;
          color: #2b3f57;
          margin-bottom: 10px;

          span {
            color: #ff5f5f;
          }
        }
      }
    }
  }

  .addpersonnel {
    padding: 0px 20px 10px;
    ul {
      li {
        margin-top: 10px;
        .p-title {
          font-size: 14px;
          font-weight: 500;
          color: #2b3f57;
          margin-bottom: 10px;

          span {
            color: #ff5f5f;
          }
        }
      }
    }
  }
  .dialog-box-box-btn {
    display: flex;
    padding: 20px 30px 20px 90px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 550;
    color: #2b3f57;
    span {
      color: #ff5f5f;
    }
  }

  .dialog-footer {
    text-align: right;
    padding: 29px 54px;
  }
}
.green {
  width: 10px;
  height: 10px;
  background: #51d7b1;
  margin: 0 10px 0 15px !important;
  border-radius: 50%;
  display: inline-block;
}

.red {
  width: 10px;
  height: 10px;
  background: rgb(255, 73, 73);
  margin: 0 10px 0 15px !important;
  border-radius: 50%;
  display: inline-block;
}
</style>